import React, { FC } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { WixStudioLogo } from '../WixStudioLogo/WixStudioLogo';
import { mobileWixStudioToolbarDataHooks } from './MobileWixStudioToolbar.dataHooks';
import Desktop from './Desktop.svg';

import s from './MobileWixStudioToolbar.scss';

interface MobileWixStudioToolbarProps extends WithTranslation {
  dataHook?: string;
  template: any;
}

const MobileWixStudioToolbarCmp: FC<MobileWixStudioToolbarProps> = ({ t, dataHook }) => {
  return (
    <>
      <div className={s.toolbar} data-hook={dataHook}>
        <WixStudioLogo dataHook={mobileWixStudioToolbarDataHooks.logo} />
      </div>
      <div className={s.goToDesktop} data-hook={mobileWixStudioToolbarDataHooks.goToDesktop}>
        <Desktop />
        <div>{t('template.viewer.goToBiggerScreen')}</div>
      </div>
    </>
  );
};

export const MobileWixStudioToolbar = withTranslation()(MobileWixStudioToolbarCmp);
