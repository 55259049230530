import React, { FC } from 'react';
import s from './WixStudioLogo.scss';
import Logo from './WixStudioLogo.svg';
import DarkLogo from './WixStudioLogoBlack.svg';

interface Props {
  dataHook?: string;
  onClick?: () => any;
  color?: 'dark' | 'light';
}

export const WixStudioLogo: FC<Props> = ({ onClick, dataHook, color = 'light' }) => (
  <div className={s.wrapper}>
    <a data-hook={dataHook} href="https://www.wix.com/studio/templates" onClick={onClick}>
      {color === 'light' ? <Logo className={s.logo} /> : <DarkLogo className={s.logo} />}
      <span className={s.hidden}>wix.com</span>
    </a>
  </div>
);
